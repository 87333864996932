import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useQueryParams,
  NumberParam,
  StringParam,
  ArrayParam,
  BooleanParam,
  withDefault,
} from 'use-query-params';
import useUpdateParams from '../../hooks/useUpdateParams';
import { useHistory, useLocation } from 'react-router-dom';
import NavSearch from '../../components/NavSearch/NavSearch';
import SearchControlBox from '../../components/SearchControlBox';
import SidebarControls from '../../components/SidebarControls';
import JobsCardContainer from '../../components/JobsCardContainer';
import {
  JobsSelector,
  searchJobs,
  setFilters,
  setFiltersFromQueryString,
  setHideMobileResults,
  setInitialFilters,
  triggerSearchJobs,
} from '../../redux/slices/jobs';
import {
  getInterestedJobs,
  getProfessionData,
  getUserData,
  NurseSelector,
} from '../../redux/slices/nurse';
import { hasAccessToken } from '../../helpers/auth';
import {
  getLastJobFilters,
  goToTop,
  handleFriendlyUrl,
  hasValidQueryString,
  markAsInterestedButton,
} from '../../helpers/utils';
import { switchNavFooter, setSearchPageElements } from '../../helpers/dom';

import './Search.style.scss';
import InterestedModal from '../../components/InterestedModal';
import SimilarJobsModal from '../../components/SimilarJobsModal';
import useModal from '../../hooks/useModal';
import {
  JobSelector,
  setEligibilityData,
  setSelectedJob,
} from '../../redux/slices/job';
import { minimumInformationCheck } from '../../components/BasicInformationForm/applicationRequests';
import SpecialtyFaqs from "../../components/SpecialtyFaqs";
import { CHANNEL_INTERACTION, TRIGGER_INTERACTION } from '../../helpers/constants/interactions';
import UseInteractions from '../../hooks/useInteractions';

const Search = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    page,
    limit,
    isLoading,
    data,
    total,
    errors,
    totalPP,
    payPackages,
    filters,
    showProfessionUser,
    hideMobileResults,
    isRedirection,
  } = useSelector(JobsSelector);
  const { selectedJob, eligibilityData } = useSelector(JobSelector);
  const _$ = window.jQuery;
  const {
    interestedJobs,
    auth: { data: nurseData, isLoading: nurseDataLoading },
  } = useSelector(NurseSelector);
  const [statusInterestedModal, toggleInterestedModal] = useModal();
  const [statusSimilarJobsModal, toggleSimilarJobsModal] = useModal();
  const [updateParams] = useUpdateParams();
  const [submitInteraction] = UseInteractions();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const matchMobile = window.matchMedia('(max-width: 992px)');
  const isMobile = matchMobile.matches;
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [alreadyTriggeredInterested, setAlreadyTriggeredInterested] = useState(false);
  const [interestedInValue, setInterestedInValue] = useState(
    params.get('interestedIn')
      ? params
          .get('interestedIn')
          .split(',')
          .map((j) => +j)
      : []
  );
  const [query] = useQueryParams({
    typeJob: StringParam,
    profession_id: NumberParam,
    specialty_ids: withDefault(ArrayParam, []),
    specialities: withDefault(ArrayParam, []),
    location: withDefault(ArrayParam, []),
    date: NumberParam,
    is_asap: StringParam,
    rate: NumberParam,
    shift: withDefault(ArrayParam, []),
    assignment_length: withDefault(ArrayParam, []),
    agency: withDefault(ArrayParam, []),
    hospital: withDefault(ArrayParam, []),
    facility: withDefault(ArrayParam, []),
    recruiter_ids: withDefault(ArrayParam, []),
    order_by: StringParam,
    order: StringParam,
    job_type: NumberParam,
    page: NumberParam,
    redirection: BooleanParam,
  });
  const [friendlyUrlData, setFriendlyUrlData] = useState(
    window?.friendlyUrlData
  );

  useEffect(() => {
    goToTop();
    setSearchPageElements();

    if (isMobile) {
      switchNavFooter();
    }

    if (hasAccessToken()) {
      dispatch(getInterestedJobs());
      dispatch(getUserData());

      if (
        showProfessionUser &&
        query.specialty_ids.length === 0 &&
        query.location.length === 0 &&
        !query.profession_id &&
        !friendlyUrlData &&
        !getLastJobFilters()
      ) {
        // Sen interested jobs params, this to prevent jobs search query to be modified when logged in
        dispatch(getProfessionData(interestedInValue.length === 0));
      }
    }
  }, []);

  useEffect(async () => {
    // Find Interested Jobs
    if (
      (window.loggedInUser || hasAccessToken()) &&
      data &&
      data.length > 0 &&
      interestedInValue.length > 0 &&
      !alreadyTriggeredInterested
    ) {
      // This was added in order to prevent double modal when user has invalid profile
      // and logs in with I'm interested flow
        // if (typeof window.validProfile !== 'undefined' && !window.validProfile) {
        //   return false;
        // }
      // Added this validation in order to avoid double trigger of this script, I know, dirty fix as usual.
      setAlreadyTriggeredInterested(true);

      // Find Interested Job in order to Apply or Open Interested Modal (Basic Information Modal)
      const job = data.find(
        (j) =>
          JSON.stringify(j.pay_package_ids) ===
          JSON.stringify(interestedInValue)
      );
      if (job) {
        // Set selected Job in order to apply Interested Job
        dispatch(setSelectedJob(job));
        const response = await minimumInformationCheck(job.job_id);
        const hasMinimumInformation = response?.able_to_apply;
        dispatch(setEligibilityData(response));

        const interestedInValueJoint = interestedInValue.join(',');
        // If candidate doesn't have minimum required information, we have to display Basic Information Modal
        // else, if user HAS minimum required information, we have to send application on background
        if (!hasMinimumInformation) {
          dispatch(setSelectedJob(job));
          // Display Basic Information Modal
          toggleInterestedModal();
        } else {
          // const applicationResponse = await sendApplication({job_ids: job.pay_package_ids}, false, false, 3);
          const responses = await (Promise.all(
            job.pay_package_ids.map(async (jobId) => {
              return await submitInteraction({
                agency_id: job.agency_id[0],
                job_id: +jobId,
                hot_lead_job_ids: [],
                similar_job_ids: [],
                channel: CHANNEL_INTERACTION.I_AM_INTERESTED,
                trigger: TRIGGER_INTERACTION.GENERAL,
              });
            })
          ));
          let interestingJobs = []
          responses.map((response, index) => {
            if(!response) {
              return;
            }
            interestingJobs.push(job.pay_package_ids[index]);
          });
          const interestedOnJobs = interestingJobs.filter(id => !!id)
          if (interestedOnJobs.length > 0) {
            _$(document).trigger('ShowSuccessCheckmarkEvt', 2500);
            const $thisButton = _$(`button.btn-interested[data-jobid="${interestedInValueJoint}"]`);
            markAsInterestedButton($thisButton);
            window.interestedJobs = await getInterestedJobs();
          }
        }

        // Update URL, removing interested in values
        let newUrl = window.location.href
          .replace('&interestedIn=' + interestedInValueJoint, '')
          .replace('interestedIn=' + interestedInValueJoint + '&', '')
          .replace('interestedIn=' + interestedInValueJoint, '');
        window.history.pushState({}, document.title, newUrl);
      }

      if (interestedInValue.length === 0) return;
      if (!interestedJobs.data.includes(parseInt(interestedInValue[0]))) {
        dispatch(getInterestedJobs());
        setInterestedInValue([]);
      }
    }
  }, [interestedInValue, data]);

  useEffect(() => {
    if (!isRedirection && !filters.initialRequest) {
      setFriendlyUrlData(null);
      updateParams(filters);
    }
  }, [filters]);

  useEffect(() => {
    const validQueryString = hasValidQueryString(query);
    const queryParams = new URLSearchParams(location.search); // eslint-disable-line

    if (
      Object.keys(validQueryString).length > 0 &&
      !isRedirection &&
      !friendlyUrlData
    ) {
      if (filters.initialRequest) {
        ///window.friendlyUrlData = null;
        dispatch(setFiltersFromQueryString(validQueryString, query.page));
      } else {
        window.friendlyUrlData = null;
        dispatch(searchJobs(query.page));
      }

      if (
        isMobile &&
        (queryParams.has('fromHome') ||
          queryParams.has('fromResultJobs') ||
          queryParams.has('shared'))
      ) {
        dispatch(setHideMobileResults(false));
      }

      if (isMobile && isFirstLoad) {
        setIsFirstLoad(false);
      }
    } else if (!isRedirection && !friendlyUrlData) {
      dispatch(setFilters({}));
    }

    if (
      queryParams.has('fromHome') ||
      queryParams.has('fromResultJobs') ||
      queryParams.has('shared')
    ) {
      queryParams.delete('fromHome');
      queryParams.delete('fromResultJobs');
      queryParams.delete('shared');

      history.replace({
        pathname: '/nurse/jobs/search',
        search: queryParams.toString(),
      });
    }
  }, [query, dispatch]);

  // Handle new window object from FRIENDLY URL
  useEffect(() => {
    if (friendlyUrlData && Object.keys(friendlyUrlData).length > 0) {
      const filters = handleFriendlyUrl(friendlyUrlData);
      dispatch(setInitialFilters(filters));
      triggerManualSearch();
    }
  }, [friendlyUrlData]);

  const triggerManualSearch = () => {
    toggleResultsContainer();
    dispatch(
      triggerSearchJobs({
        page: 1,
        limit: 20,
      })
    );
  };

  const toggleResultsContainer = () => {
    goToTop();
    setShowMobileFilters(false);
    dispatch(setHideMobileResults(!hideMobileResults));
    setFriendlyUrlData(null);
  };

  const toggleMobileFilters = () => {
    setShowMobileFilters(!showMobileFilters);
  };

  const applyMobileFilters = () => {
    setShowMobileFilters(false);
    dispatch(setHideMobileResults(false));
    dispatch(
      triggerSearchJobs({
        page: 1,
        limit: 20,
      })
    );
  };

  const scrollFetchData = (page) => {
    dispatch(
      triggerSearchJobs(
        {
          page,
          limit: 20,
        },
        true
      )
    );
  };

  const getTitleHeader = (typeJob) => {
    const jobTypeMap = {
      irp: 'IRP',
      perDiem: 'Per Diem',
      domestic: 'Travel',
    };

    return `${jobTypeMap[typeJob] || typeJob} Assignments`;
  };

  return (
    <>
      <div className="padding-sticky-bar">
        <section className="full-width">
          <NavSearch
            toggle={toggleResultsContainer}
            showResultItems={!hideMobileResults}
            title={
              showMobileFilters ? 'Filters' : getTitleHeader(filters.typeJob)
            }
            toggleMobileFilters={toggleMobileFilters}
            filters={filters}
            hideSubNav={(
              (!isMobile && window?.friendlyUrlData?.location)
              || (isMobile && !hideMobileResults && window?.friendlyUrlData?.location)
            )}
          />
          <SearchControlBox
            triggerSearch={triggerManualSearch}
            showResultItemsClass={
              isMobile ? (hideMobileResults ? '' : 'hidden') : ''
            }
            seoFriendlyUrlData={window?.friendlyUrlData}
            showSeoCity={(isMobile && !hideMobileResults) || !isMobile}
          />
        </section>

        <section
          className={`full-width bg-gray results-container ${
            isMobile ? (hideMobileResults ? 'hidden' : '') : ''
          }`}
        >
          {showMobileFilters ? (
            <>
              <SidebarControls isMobile={isMobile} />
              <div className="control-sticky-bottom full-width">
                <button
                  className="btn btn-black full-width"
                  onClick={applyMobileFilters}
                >
                  APPLY
                </button>
              </div>
            </>
          ) : (
            <div className="container-custom flex">
              <div className="sidebar-container">
                <SidebarControls isMobile={isMobile} />
              </div>
              <JobsCardContainer
                isLoading={isLoading}
                jobs={data}
                total={total}
                totalPP={totalPP}
                currentPage={page}
                limit={limit}
                interestedJobs={interestedJobs.data}
                filters={filters}
                showResultItemsClass={
                  isMobile ? (hideMobileResults ? 'hidden' : '') : ''
                }
                isMobile={isMobile}
                scrollFetchData={scrollFetchData}
                errors={errors}
                payPackages={payPackages}
                toggleToCriteria={toggleResultsContainer}
                toggleInterestedModal={toggleInterestedModal}
                toggleSimilarJobsModal={toggleSimilarJobsModal}
                showingMobileSeoCity={window?.friendlyUrlData && isMobile}
                showingFaq={window?.friendlyUrlData?.specialty?.seo}
              />
            </div>
          )}
        </section>
      </div>
      {/* Job Detail Modal */}
      {statusInterestedModal && (<InterestedModal
        job={selectedJob}
        nurseProfile={nurseData?.profile}
        toggle={toggleInterestedModal}
        eligibilityData={eligibilityData}
        toggleSimilarJobsModal={toggleSimilarJobsModal}
      />)}

      {/* Specialties FAQ's*/}
      {window?.friendlyUrlData?.specialty?.seo && (
        <SpecialtyFaqs data={window?.friendlyUrlData?.specialty?.seo} />
      )}

      {statusSimilarJobsModal && (
        <SimilarJobsModal job={selectedJob} toggle={toggleSimilarJobsModal} />
      )}
    </>
  );
};

export default Search;
