import { NavLink } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import Avatar from 'react-avatar';
import { getCsrfToken, hasAccessToken } from '../../helpers/auth';
import useOutsideAlerter from "../../hooks/useOutsideAlerter";

import { ReactComponent as WanderlyWhiteLogo } from '../../assets/images/logos/wanderly-white.svg';
import { ReactComponent as WanderlyLogo } from '../../assets/images/icons/logo-blue.svg';
import { ReactComponent as DownArrow } from '../../assets/images/icons/darken/down-arrow.svg';
import NAVIGATION_MENU from "../../helpers/constants/navigationMenu";

// Styles
import './HeroSection.style.scss';
import { useSelector } from 'react-redux';
import { NotificationsSelector } from '../../redux/slices/notifications';


const NavigationMenu = ({ isActive = '', type= '', toggleModal }) => {
  const formEl = useRef();
  const referenceRef = useRef(null);
  const [openSettings, setOpenSettings] = useState(false);
  const [user, setUser] = useState(null);
  const [itemsMenu, setItemsMenu] = useState([]);
  const [fullname, setFullName] = useState('');
  const [resourcesTabOpen, setResourcesTabOpen] = useState(false);
  const wrapperRef = useRef(null);
  const [isClickOutside, resetStatus] = useOutsideAlerter(wrapperRef);
  const { inbox } = useSelector(NotificationsSelector);

  useEffect(() => {
    const retrievedUserObject = localStorage.getItem('userAuth');
    const parsedUser = JSON.parse(retrievedUserObject) || {first_name: '', last_name:''};

    setUser(parsedUser);
    setFullName(`${parsedUser?.first_name || ''} ${parsedUser?.last_name || ''}`);
    setItemsMenu(getNavigationMenu());
    document.addEventListener('mousedown', handleDocumentClick);

    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    if (isClickOutside) {
      setResourcesTabOpen(false);
    }
  }, [isClickOutside]);

  const handleDocumentClick = (event) => {
    if (!referenceRef?.current?.contains(event.target)) {
      setOpenSettings(false);
    }
  };

  // EVENT HANDLERS
  const onLogoutClick = (e) => {
    e.preventDefault();
    localStorage.removeItem('accessToken');
    localStorage.removeItem('tokenId');
    localStorage.removeItem('userAuth');
    localStorage.removeItem('SUQModal');
    formEl.current && formEl.current.submit();
  };

  const getAvatar = () => (
    <>
      {user?.image ? (
        <Avatar
          color="#4285F4"
          className="me-2 recruiter-photo"
          round
          size="38"
          src={user?.image}
          style={{ position: 'relative', top: '-2px' }}
        />
      ) : (
        <Avatar name={fullname} round size="35" />
      )}
    </>
  );

  const Logo = () => (
    <div className="nav-menu-logo">
      <NavLink to="/">
        {type === 'yellow' ? <WanderlyWhiteLogo className="nav-menu-logo" /> : <WanderlyLogo className="nav-menu-logo" /> }
      </NavLink>
    </div>
  );

  const getNavigationMenu = () => {
    const isUserLoggedIn = hasAccessToken();

    if (isUserLoggedIn) {
      return NAVIGATION_MENU.filter((item) => item.showLoggedIn);
    } else {
      return NAVIGATION_MENU.filter((item) => !item.loggedIn);
    }
  }

  const ResourcesDropdown = ({ items, tabOpened }) => (
      <ul
        role="menu"
        className={`resources-menu-react ${
          tabOpened ? 'resources-menu-opened' : ''
        }`}
      >
        {
          items.map((item) => (
            <li className={`resources-tab ${item.classname}`} onClick={() => { setResourcesTabOpen(false) }} >
              <a aria-current="false" href={item.href}>
                {item.title}
              </a>
            </li>
          ))
        }
      </ul>
  );

  const Navigation = ({ items }) => {
    const childrenOnClick = (e) => {
      e.preventDefault();
      setResourcesTabOpen((prevValue) => !prevValue);
      resetStatus()
    }

    return (
      <div className={`nav-menu-items ms-lg-4 `} >
        {
          items.map((item) => {
            return (
              <div className={`nav-menu-items-link ${item.classname}`} key={item.id} ref={wrapperRef}>
                {
                  item.children
                    ? (<>
                      <button
                        className={`link ${item.classname} ${ resourcesTabOpen ? 'resource-active' : ''} ${isActive && isActive === item.activeItem ? 'item-active' : ''}`}
                        onClick={childrenOnClick}
                      >
                        {item.title} <DownArrow style={{ marginLeft: 2, marginTop: 2 }} />
                      </button>
                      {resourcesTabOpen && <ResourcesDropdown items={item.children} tabOpened={resourcesTabOpen} />}
                    </>)
                    : (
                      <a
                        className={`link ${item.classname} ${isActive && isActive === item.activeItem ? 'item-active' : ''}`.trim()}
                        href={item.href}
                      >
                        {item.title}{item.showCounter && inbox > 0 ?
                        <span className='circle-notification'>{inbox}</span> : null}
                      </a>
                    )
                }
              </div>
            )
          })
        }
      </div>
    )
  };

  return (
    <>
      <nav className={`nav-menu-desktop shadow ${type}`}>
        <Logo />
        <Navigation items={itemsMenu} />

        {!hasAccessToken()
          ? (<>

              <div className="nav-menu-signin">
                <div>
                  <a
                    id="sign-in-btn"
                    className="signInButtonLight"
                    href="/nurse/login"
                  >
                    Sign In
                  </a>
                </div>
              </div>
              <div className="nav-menu-join">
                <button id="join-for-free-btn" className="btn-join-react">
                  JOIN FOR FREE
                </button>
              </div>
            <div className="nav-menu-join">
              <buttoon onClick={toggleModal} id="get-app-btn" className="btn-join-react">
                GET THE APP
              </buttoon>
            </div>
            </>)
          : (<>
            <div className="nav-menu-authenticated" ref={referenceRef}>
              <div
                className="session-dropdown"
                style={{ cursor: 'pointer', display: 'flex' }}
                onClick={() => {
                  setOpenSettings(!openSettings);
                }}
              >
                <div style={{ width: 35 }}>
                  {getAvatar()}
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  className={`session-dropdown-control ${
                    openSettings ? 'session-dropdown-control-open' : ''
                  }`}
                >
                  <span className="username">
                    {fullname}
                  </span>
                  <DownArrow style={{ marginRight: 5, marginTop: 3 }} />
                </div>

                <ul
                  role="menu"
                  className={`settings-menu ${
                    openSettings ? 'settings-menu-opened' : ''
                  }`}
                >
                  <li className="resources-tab">
                    <a
                      aria-current="false"
                      href="/nurse/accountsettings/notification"
                    >
                      Notifications
                    </a>
                  </li>
                  <li className="resources-tab">
                    <a
                      aria-current="false"
                      href="/nurse/accountsettings/preference"
                    >
                      Job Preferences
                    </a>
                  </li>
                  <li className="nurse-navbar-logout">
                    <a
                      href="/logout"
                      onClick={(e) => {
                        onLogoutClick(e);
                      }}
                    >
                      Sign Out
                    </a>
                    <form
                      id="logout-form"
                      action="/logout"
                      method="POST"
                      style={{ display: 'none' }}
                      ref={formEl}
                    >
                      <input type="hidden" name="_token" value={getCsrfToken()} />
                    </form>
                  </li>
                </ul>
              </div>
            </div>
          </>)}
      </nav>

      <nav className="nav-menu-mobile">
        <div className="nav-menu-mobile-logo">
          <NavLink to="/">
            <WanderlyLogo className="nav-menu-logo" />
          </NavLink>
        </div>
      </nav>
    </>
  );
};

export default NavigationMenu;
