// External Modules
import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

// Redux Actions & Selectors
import {
  JobsSelector,
  setInitialFilters,
  setFilters,
} from '../../redux/slices/jobs';
import { useDispatch, useSelector } from 'react-redux';
import {
  populateJobsCount,
  triggerTotalJobsCount,
  triggerTotalJobsCountForLocation,
} from '../../redux/slices/jobsCounter';

// Internal Modules
import ProfessionDropdown from '../ProfessionDropdown';
import SpecialtyDropdown from '../SpecialtyDropdown';
import LocationDropdown from '../LocationDropdown';
import TypeJobsContainer from '../TypeJobsContainer';

// Images
import searchIcon from '../../assets/images/icons/search.svg';

// Helpers
import { activeSearchMenu } from '../../helpers/dom';
import { getLastJobFilters, paramsToUrl, parseFilters } from '../../helpers/utils';

// Hooks
import useUpdateParams from '../../hooks/useUpdateParams';

const SearchJobsBox = ({ wandaLogo }) => {
  const dispatch = useDispatch();
  const { filters } = useSelector(JobsSelector);
  const [redirectToSearchPage] = useUpdateParams();
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 992px)',
  });

  const setTypeOfJob = (type) => {
    dispatch(
      setFilters({
        typeJob: type,
       // profession_id: null,
       // specialty_ids: null,
        location: null,
        date: null,
        is_asap: false,
        rate: null,
        shift: [],
        assignment_length: [],
        hospital: null,
        agency: null,
        recruiter_ids: null,
        order_by: null,
        order: 'desc',
        sort_dropdown: null,
      })
    );
  };

  useEffect(() => {
    // Added this for preserve last search functionality
    const jbSrchFilters = getLastJobFilters() || {};
    dispatch(
      setInitialFilters({
        initialRequest: false,
        fromHome: true,
        ...jbSrchFilters
      })
    );
  }, []);

  const triggerSearch = () => {
    const newFilters = { fromHome: true };

    if (!!isLargeScreen)  {
      dispatch(
        setInitialFilters({
          initialRequest: true,
          fromHome: false // Added this for preserve last search functionality
        })
      );
      activeSearchMenu();
      redirectToSearchPage(newFilters);
    } else {
      window.location.href = `/nurse/jobs/search?${paramsToUrl( { ...filters, ...newFilters}, 1)}`;
    }
  };

  useEffect(async () => {
    if (!filters?.initialRequest) {
      let newFilters = filters;
      if (filters.hasOwnProperty('specialty_ids')) {
        let { specialty_ids, ...rest } = filters;
        newFilters = rest;
      }

      const params = parseFilters(newFilters);
      const totalJobsCount = await dispatch(triggerTotalJobsCount(params));
      const totalLocationJobsCount = await dispatch(
        triggerTotalJobsCountForLocation(params),
      );

      await dispatch(
        populateJobsCount({
          ...totalJobsCount,
          locations: totalLocationJobsCount,
        }),
      );
    }
  }, [filters]);

  return (
    <>
      <TypeJobsContainer
        selectedTypeJob={filters.typeJob}
        setTypeOfJob={setTypeOfJob}
        isLargeScreen={isLargeScreen}
      />
      <div className="filter-container">
        <div className="filter-container--professions">
          <ProfessionDropdown />
        </div>
        <div className="filter-container--specialties">
          <SpecialtyDropdown placeholder="Enter Specialties" />
        </div>
        <div className="filter-container--location">
          <LocationDropdown triggerOnMenuClose={false} />
        </div>

        <div className="filter-container--action">
          <button
            type="button"
            className="btn btn-primary cta_before_login_search_button"
            onClick={triggerSearch}
          >
            <img src={searchIcon} alt="Search Jobs" className="cta_before_login_search_button"/> <span className="cta_before_login_search_button">SEARCH</span>
          </button>
        </div>
      </div>

      {wandaLogo && (
        <div className="wanda-logo">
          <img src={wandaLogo} alt="Wanda logo" />
        </div>
      )}
    </>
  );
};

export default SearchJobsBox;
