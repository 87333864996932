import { Button, Checkbox } from '@orionhcs/wanda-js-components';
import React, {useCallback, useEffect, useReducer, useState} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as AddIcon } from '../../assets/images/icons/add-file.svg';
import { ReactComponent as IconSpecialties } from '../../assets/images/icons/icon-search.svg';
import { ReactComponent as IconDelete } from '../../assets/images/icons/trashcan-icon.svg';

import ApplyFormConfig from './applyFormConfig';
import DropdownControl from '../DropdownControl';
import Input from '../Form/Input/Input';
import InputMask from '../Form/InputMask/';
import ProfessionDropdown from '../Form/ProfessionDropdown';
import SpecialtyDropdown from '../Form/SpecialtyDropdown';
import {
  getInterestedJobs,
  updateProfile,
} from './applicationRequests';
import css from './BasicInformationForm.module.scss';
import useBreakpoint from '../../hooks/useBreakpoint';
import { markAsInterestedButton, myMD5 } from '../../helpers/utils';
import UseResume from "../../hooks/useResume";
import UploadResume from "../UploadResume";
import UseInteractions from "../../hooks/useInteractions";
import {CHANNEL_INTERACTION, TRIGGER_INTERACTION} from "../../helpers/constants/interactions";
import useBasicApplicationForm from "../../hooks/useBasicApplicationForm";
import { professionsSelector } from '../../redux/slices/professions';
import { useSelector } from 'react-redux';
import { getSpecialties } from '../../redux/slices/specialties';

const BasicInformationForm = ({ job, profile, toggle, eligibilityData, toggleSimilarJobsModal  }) => {
  const optionalResume = true;
  const screenSize = useBreakpoint();
  const [isLoading, setIsLoading] = useState(false);
  const { requirements } = eligibilityData || {};
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 768px)',
  });
  const { professions } = useSelector(professionsSelector);

  const initialValues = {
    specialties: [],
    selectedProfession: null,
    userSpecialties: [],
    profile: null,
  };

  const [formValues, setFormValues] = useReducer(
    (curValues, newValues) => ({ ...curValues, ...newValues }),
    initialValues
  );

  const {
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    getValues,
    trigger,
    reset,
    control,
    unregister,
    setError,
    clearErrors
  } = useForm({ mode: 'onChange' });

  const {
    parseExperienceSpecialties,
    isDisabled,
    isValidJobRequirements,
    removeSpecialty,
    getErrors,
    matchSpecialtyProfileWithJob,
    onSelectOption,
    addSpecialtyToList,
    validationError,
    setValidationError,
    asyncValidation,
  } = useBasicApplicationForm(unregister, getValues, setValue, formValues, setFormValues, trigger, job, setError, clearErrors);

  const [
    file,
    handleFileChange,
    errorUploadMessage,
    isLoadingApplication,
    inputFileRef,
    clearData,
    submitResumeFile,
    hasResume
  ] = UseResume(setValue, trigger);

  const [submitInteraction] = UseInteractions();

  // Preset Job Profession + specialties
  const getSpecialtiesNurse = async (jobSpecialties, professionId) => {
    try {
      const specialties = await Promise.all(
        jobSpecialties.map(async (item) => {
          if (!item) return false;
          const specialty = await getSpecialties(item, professionId, true);
          const specialtyWithData = { disallowToDelete: true, ...specialty };

          if (specialty.value) {
            setValue(`specialties[${specialty.value}]`, specialtyWithData);
          }

          return specialtyWithData;
        })
      );

      setFormValues({ specialties });
      asyncValidation();
      await trigger(`specialties_experience`);
    } catch (error) {
      console.log(error);
    }
  };

  /* RESET FORM*/
  useEffect(
    () => () => {
      reset({
        first_name: '',
        last_name: '',
        profession: '',
        specialties: '',
        phone: '',
        email: '',
        source_knowledge: '',
        current_working_traveler: '',
        current_facility: '',
        address: '',
        city: '',
        zip: '',
        state: '',
        legal_worker: false
      });
    },
    []
  );

  const fillForm = useCallback(async () => {
    if (Object.keys(profile).length > 0) {
      setIsLoading(true);

      if (profile?.first_name) {
        setValue('first_name', profile?.first_name);
        await trigger('first_name');
      }

      if (profile?.last_name) {
        setValue('last_name', profile?.last_name);
        await trigger('last_name');
      }

      if (profile?.home_phone_number) {
        const cleaned = profile.home_phone_number.replace(/[()\s-]+/g, '');
        const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        setValue('phone', formatted);
        await trigger('phone');
      }

      if (profile?.email) {
        setValue('email', profile?.email);
        await trigger('email');
      }

      if (profile?.legal_worker) {
        setValue('legal_worker', profile?.legal_worker);
        await trigger('legal_worker');
      }

      if (profile?.active_profession) {

        if (!job?.profession_id) {
          const { profession_id } = profile.active_profession;
          setValue('profession', profession_id);
          setFormValues({
            selectedProfession: profession_id,
          });
        }
        setValue('years_experience_profession', profile?.active_profession?.years_exp);
        await trigger('years_experience_profession');
      }

      if (profile?.specialties?.length) {
        const specialties = matchSpecialtyProfileWithJob(profile, job);

        setFormValues({
          specialties,
        });

        const uploadPromises = specialties.map(async (specialty) => {
          setValue(`specialties[${specialty.value}]`, specialty);
          setValue(`specialties_experience[${specialty.value}]`, specialty.years_exp);
        });

        await Promise.all(uploadPromises);
        isValidJobRequirements(profile);
        await trigger(`specialties_experience`);
      }

      setIsLoading(false);
    } else {
      const specialtyWithData = {
        value: 0,
        label: null,
        fakeLabel: null,
        disallowToDelete: true,
      };
      const specialties = [specialtyWithData];

      setFormValues({
        specialties,
      });
    }
  },[profile?.id]);

  /** Fill data with profile user */
  useEffect(async () => {
    await fillForm();

    setTimeout(async () => {
      await trigger();
      asyncValidation();
    }, 100);
  }, [profile?.id]);

  useEffect(() => {
    if (job && professions && professions.length > 0) {
      if (job?.profession_id) {
        setValue('profession', job?.profession_id);
        setFormValues({ selectedProfession: job?.profession_id });
      }

      if (job?.specialty.length) {
        getSpecialtiesNurse(job?.specialty, job?.profession_id);
      }
    }
  }, [job, professions]);

  /*Validate job requirement*/

  /**
   * Bind loading css class to sent element (e), if element it's not sent, a full screen spinner will be displayed
   * @return {void}
   * @param dataForm {Object} React Hook Form object
   */
  const onSubmit = async (dataForm) => {
    setIsLoading(true);
    setValidationError(null);

    if (!dataForm.specialties || !dataForm.specialties_experience) {
      setValidationError({
        type: 'specialty',
        message: `This job requires ${job.speciality_names[0]} Specialty`,
      });
      setIsLoading(false);
    }

    const specialties = parseExperienceSpecialties(dataForm, profile);

    // Map parsed data form
    dataForm = {
      profession: dataForm.profession,
      experience: dataForm.years_experience_profession,
      profile: {
        first_name: dataForm.first_name,
        last_name: dataForm.last_name,
        home_phone_number: dataForm.phone,
        email: dataForm.email,
        legal_worker: dataForm.legal_worker
      },
      specialties,
      check_duplicate: true
    };

    const validateData = isValidJobRequirements(dataForm, job);

    if (validateData) {
      setValidationError(null);

      // Update Profile
      const { success: successProfile, message } = await updateProfile(dataForm);

      if (successProfile) {
        const _$ = window.jQuery;

        // Update Resume
        if (!hasResume) {
          if (inputFileRef?.current && file) {
            await submitResumeFile(null, true);
          }
        }

        // Submit Interaction
        await (Promise.all(
          job.pay_package_ids.map(async (jobId) => {
            return await submitInteraction({
              agency_id: job.agency_id[0],
              job_id: +jobId,
              hot_lead_job_ids: [],
              similar_job_ids: [],
              channel: CHANNEL_INTERACTION.I_AM_INTERESTED,
              trigger: TRIGGER_INTERACTION.GENERAL,
            });
          })
        ));

        if (successProfile) {
          const $thisButton = _$(
            `button.btn-interested[data-jobid="${job.pay_package_ids.join(',')}"]`
          );

          if (job.agency_allows_interactions === true) {
            markAsInterestedButton($thisButton);
            window.interestedJobs = await getInterestedJobs();
          }

          toggle();

          if (job.agency_allows_interactions === true) {
            _$(document).trigger('ShowSuccessCheckmarkEvt', 2500);
          }

          //Toggle Similar Jobs Modal
          if (job.agency_allows_interactions === false) {
            toggleSimilarJobsModal();
          }
        } else {
          setValidationError({
            type: 'custom',
            message: 'An error has occurred, please try again later.'
          });
          setTimeout(() => setValidationError(null), 3000);
        }
      } else if(message?.errors) {
        for (let key in message.errors) {
          const fieldName = key === 'home_phone_number' ? 'phone' : key;
          setError(fieldName, { type: 'custom', message: message.errors[key] })
        }
      } else {
        setValidationError({
          type: 'custom',
          message: 'An error has occurred, please try again later.'
        });
        setTimeout(() => setValidationError(null), 3000);
      }
    } else {
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  const renderField = (field, props) => {
    switch (field.type) {
      case 'input':
        return (
          <Input
            className={`${errors[field.key] ? 'invalid-input' : ''} ${
              css['input-text']
            }`}
            defaultValue={props.field.value}
            disabled={isDisabled(field.key, profile)}
            verified={isDisabled(field.key, profile)}
            errors={errors[field.key]}
            isOptional={field.isOptional}
            isRequired={!field.isOptional}
            label={field.label}
            labelClass={`${css.label} ${
              field.key === 'years_experience_profession'
                ? css['label-exp']
                : ''
            }`}
            maxLength={field.key === 'years_experience_profession' ? 2 : false}
            name={field.name}
            onChange={async (e, value) => {
              setValue(props.field.name, value);
              await trigger(props.field.name);
              asyncValidation();
            }}
            onlyNumbers={field.key === 'years_experience_profession'}
            placeholder={field.placeHolder}
            type={field.typeText}
            tooltip={(field.key == 'email' && isDisabled(field.key, profile))?  'To update email, please contact support@wanderly.us' : ''}
          />
        );
      case 'profession':
        return (
          <>
            <ProfessionDropdown
              classname={`${errors[field.key] ? 'invalid' : ''} ${
                css['input-dropdown']
              }`}
              errors={errors[field.key]}
              isControlled
              label={field.label}
              labelClass={css.label}
              onChange={onSelectOption}
              showIndicator={false}
              showPlaceholder={false}
              value={props.field.value}
            />

            {validationError?.type === 'profession' && (
              <div className="col-12 mt-2 invalid-message mb-2">{validationError.message}</div>
            )}
          </>
        );
      case 'specialties':
        return (
          <SpecialtyDropdown
            classname={`${errors[field.key] ? 'invalid' : ''} ${
              css['input-dropdown']
            }`}
            errors={errors[field.key]}
            icon={<IconSpecialties />}
            isControlled
            isMulti={false}
            label={field.label}
            labelClass={css.label}
            name={field.name}
            onChange={onSelectOption}
            placeholder={field.placeHolder}
            profession={job?.profession_id}
            showIcon
            showPlaceholder={false}
            value={props.field.value}
            showCounters={false}
          />
        );
      case 'maskedInput':
        return (
          <InputMask
            className={`${css['input-text']}`}
            errors={errors[field.key]}
            label={field.label}
            labelClass={css.label}
            mask={field.mask}
            name={props.field.name}
            onChange={async (value) => {
              const cleanValue = value.replaceAll('_', '').trim();
              setValue(props.field.name, cleanValue);
              await trigger(props.field.name);
              asyncValidation();
            }}
            placeholder={field.maskPlaceHolder}
            required
            value={props.field.value}
            disabled={isDisabled(field.key, profile)}
            verified={isDisabled(field.key, profile)}
            tooltip={(field.key == 'phone' && isDisabled(field.key, profile))? 'To update Phone Number, please contact support@wanderly.us' : ''}
          />
        );
      case 'dd':
        return (
          <DropdownControl
            classname={`${errors[field.key] ? 'invalid' : ''}`}
            errors={errors[field.key]}
            isOptional
            label={field.label}
            labelClass={css.label}
            name={field.key}
            onChange={onSelectOption}
            options={field.options}
            placeholder={field.placeHolder}
          />
        );
      case 'checkbox':
        return (
          <>
            <Checkbox
              className={`${errors[field.key] ? 'invalid' : ''} ${css['checkbox-authorized']} mb-0`}
              color="green"
              id="selectAll-checkbox"
              isRequired={!field.isOptional}
            label="I am legally authorized to work in the United States."
              name={field.name}
              onChange={async (e) => {
                setValue(field.name, e.target.checked);
                await trigger(field.name);
                asyncValidation();
              }}
              value={field.name}
              isChecked={props.field.value}
            />
            {errors?.[field.key] && <div className="invalid-message">This field is required</div>}
          </>
        );
      default:
        return null;
    }
  };

  const applyFormFields = ApplyFormConfig;
  const { ...formFields } = {
    ...applyFormFields,
    years_experience_profession: {
      ...applyFormFields['years_experience_profession'],
      label:
        ['xs', 'sm'].indexOf(screenSize) !== -1
          ? 'Experience'
          : 'Years Experience',
      rules: {
        ...applyFormFields['years_experience_profession']['rules'],
        min: {
          value: requirements?.requiredJobExperience || 1,
          message: requirements?.professionMessage,
        },
      },
    },
  };

  return (
    <>
      <form
        className={`${css['interested-form']}`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="row">
          {Object.values(formFields)
            .filter((f) => !f.hide)
            .map((f, i) => {
              const shouldRender = f.depensOn
                ? getValues(f.depensOn)?.value === f.depensValue
                : true;

              return shouldRender ? (
                <div className={f.colClass} key={`${f.key}-${i}`}>
                  <Controller
                    control={control}
                    defaultValue={getValues(f.key)}
                    name={f.key}
                    render={(props) => renderField(f, props)}
                    rules={f.rules}
                  />
                </div>
              ) : null;
            })}
        </div>

        {formValues.specialties.length > 0 &&
          formValues.specialties.map((specialty, index) => (
            <div key={specialty.value} className="row">
              <div
                className={`col-8 col-sm-6 order-5 order-sm-5 ${css['container-field']}`}
              >
                <Controller
                  control={control}
                  defaultValue={getValues(`specialties[${specialty.value}]`)}
                  name={`specialties[${specialty.value}]`}
                  render={(props) => (
                    <>
                      <div className="">
                        <div
                          className={`${css['specialty-container-input']} ${
                            index === 0
                              ? css['specialty-container-input-full']
                              : ''
                          }`}
                        >
                          <SpecialtyDropdown
                            classname={`${css['input-dropdown']}`}
                            errors={getErrors(
                              errors,
                              `specialties[${specialty.value}]`,
                              'specialties'
                            )}
                            icon={<IconSpecialties />}
                            // isClearable={specialty.isClearable || specialty.showCross}
                            isClearable
                            isRequired
                            isControlled
                            isMulti={false}
                            label="Specialty"
                            labelClass={css.label}
                            name={`specialties[${specialty.value}]`}
                            onChange={onSelectOption}
                            profession={formValues.selectedProfession}
                            showIcon
                            showPlaceholder={false}
                            value={props.field.value}
                            showCounters={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  rules={
                  !specialty.isClearable
                    ? {
                    validate: (value) => typeof value !== 'undefined',
                    required: 'This field is required',
                    } : {
                      validate: (value) => {
                        const yearExp = getValues(
                        `specialties_experience[${specialty.value}]`
                        );

                        if (!yearExp) {
                          return true;
                        }
                        return !!value;
                    },
                  }
                }
                />
                {!isLargeScreen && !specialty.disallowToDelete && formValues.specialties.length > 1 && (
                  <IconDelete
                    className={css['delete-specialty-icon']}
                    onClick={() => removeSpecialty(specialty.value)}
                  />
                )}
              </div>

              <div
                className={`col-4 col-sm-6 order-5 order-sm-5 ${css['container-field']}`}
              >
                <div className="flex">
                  <Controller
                    control={control}
                    defaultValue={getValues(
                      `specialties_experience[${specialty.value}]`
                    )}
                    name={`specialties_experience[${specialty.value}]`}
                    render={(props) => (
                      <>
                        <Input
                          className={`${css['input-text']}`}
                          classNameContainer={`${css['input-text-container']}`}
                          defaultValue={props.field.value}
                          errors={getErrors(
                            errors,
                            `specialties_experience[${specialty.value}]`,
                            'specialties_experience'
                          )}
                          isOptional={false}
                          isRequired={!specialty.isClearable}
                          label="Years Experience"
                          labelClass={`${css.label} ${css['label-exp']}`}
                          maxLength={2}
                          name={`specialties_experience[${specialty.value}]`}
                          onChange={async (e, value) => {
                            setValue(props.field.name, value);
                            await trigger();
                            asyncValidation();
                          }}
                          onlyNumbers
                          type="text"
                        />
                      </>
                    )}
                    rules={
                      specialty.isClearable
                      ? {
                          maxLength: {
                            value: 2,
                            message: 'Only allow 2 numbers.',
                          },
                          min: {
                            value: 1,
                            message: ['xs', 'sm'].indexOf(screenSize) !== -1 ? `Experience 1` : `Years Experience 1`,
                          },
                          validate: (value) => {
                            const specialtySelected = getValues(
                            `specialties[${specialty.value}]`
                            );

                            if (!specialtySelected) {
                            return true;
                         }

                          return !!value;
                        },
                      }
                      : {
                        required: 'This field is required',
                        maxLength: {
                          value: 2,
                          message: 'Only allow 2 numbers.',
                        },
                        min: {
                          value: requirements?.agencyRequiredExperience || 1,
                          message: `${['xs', 'sm'].indexOf(screenSize) === -1 ? 'Years' : ''} Experience ${requirements?.agencyRequiredExperience ? requirements?.agencyRequiredExperience : 'value is 0 not allowed'}`
                        },
                    }
                  }
                  />
                  {isLargeScreen && !specialty.disallowToDelete && (
                    <IconDelete
                      className={`${css['delete-specialty-icon']} qaquy`}
                      onClick={() => removeSpecialty(specialty.value)}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}

        {validationError?.type === 'specialty' && (
          <div className="col-12 mt-2 invalid-message mb-4">{validationError.message}</div>
        )}

        <div className="col-12">
          <button
            className={`link-button ${css['add-specialties-btn']}`}
            onClick={addSpecialtyToList}
            type="button"
          >
            <AddIcon fill="#06759A" />{' '}
            <span className={'vcenter'}>ADD MORE SPECIALTIES</span>
          </button>
        </div>

        {/* Add Resume */}
        <div className="col-12">
          <Controller
            control={control}
            defaultValue={getValues(`resume`)}
            name="resume"
            render={(props) => (
              <>
                <UploadResume
                  clearData={clearData}
                  displayTitle={!isLargeScreen}
                  errorMessage={errors?.resume ? 'This field is required' : errorUploadMessage}
                  file={file}
                  handleFileChange={handleFileChange}
                  inputFileRef={inputFileRef}
                  isLoadingApplication={isLoadingApplication}
                  classname={css['add-specialties-btn']}
                  isOptional={optionalResume}
                />
              </>
            )}
            rules={{
              validate: (value) => {
                if (profile?.has_resume && value) return true;

                if (optionalResume) {
                  return true;
                }

                return !!value;
              },
            }}
          />
        </div>

        {validationError?.type === 'custom' && (
          <div className="col-12 justify-center mt-2 alert alert-danger mb-4">
            {validationError.message}
          </div>
        )}

        <div className="col-12 text-center">
          <Button
            className={`${css['submit-btn']} ${
              !isValid || Object.values(errors).length > 0 ? `disabled ${css['disabled']}` : ''
            } ${isLoading ? css['loading'] : ''} m-0 py-2 px-5 cta_after_login_profile_form_im_interested`}
            type="submit"
            disabled={!isValid || Object.values(errors).length > 0}
          >
            <span className={'ms-2 vcenter cta_after_login_profile_form_im_interested'}>Submit Application</span>
          </Button>
        </div>
      </form>
    </>
  );
};

export default React.memo(BasicInformationForm);
