import { PermanentSimilarJobCard, TravelSimilarJobCard } from '../SimilarJobCard';
import React, { useImperativeHandle, useEffect, useState, forwardRef } from 'react';
import css from './SimilarJobList.module.scss';

const SimilarJobList = forwardRef(({ data, setDisabledSendButton }, ref) => {
  const [checkedJobs, setCheckedJobs] = useState([]);
  const [uncheckedJobs, setUncheckedJobs] = useState([]);

  const onClickJobCard = (e, jobId) => {
    const isViewDetail = e.target.getAttribute('data-isViewDetail');

    if (!isViewDetail) {
      let newCheckedJobs = [];

      if (checkedJobs.includes(jobId)) {
        newCheckedJobs = checkedJobs.filter((item) => item !== jobId);
      } else {
        newCheckedJobs = [...checkedJobs, jobId];
      }

      let nonCheckedJobs = data.filter(j => !newCheckedJobs.includes(j.job_id)).map(j => j.job_id);
      setCheckedJobs(newCheckedJobs);
      setUncheckedJobs(nonCheckedJobs);
    }
  };

  const redirectToSearchJobs = () => {
   // window.location = `${Config.WAP_URL}/nurse/jobs/search`;
  };

  useImperativeHandle(ref, () => ({
    getCheckedJobs() {
      return checkedJobs;
    },
    getSimilarJobs() {
      return data.map(j => j.job_id);
    },
    getUnCheckedJobs() {
      return uncheckedJobs;
    },
  }));

  useEffect(() => {
    if (data?.length) {
      const jobs = data.map((job) => job.job_id);

      setCheckedJobs(jobs);
    }
  }, [data]);

  useEffect(() => {
    setDisabledSendButton(!(checkedJobs?.length > 0));
  }, [checkedJobs]);

  return (
    <div className={`${css.root}`}>
      {data && data?.length > 0 && (
        <div className={`col-12 ${css['container-cards']}`} style={{ display: 'flex' }}>
          {data.map((item, index) => {

            if (+item.job_type === 1) { // Travel
              return (
                <TravelSimilarJobCard
                  key={index}
                  checked={checkedJobs.includes(item?.job_id)}
                  data={item}
                  onClickJobCard={onClickJobCard}
                />
              )
            }

            if (+item.job_type === 2) { // Permanent
              return (
                <PermanentSimilarJobCard
                  key={index}
                  checked={checkedJobs.includes(item?.job_id)}
                  data={item}
                  onClickJobCard={onClickJobCard}
                />
              )
            }
          })}
        </div>
      )}
    </div>
  );
});

export default SimilarJobList;
