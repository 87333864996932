const NAVIGATION_MENU = [
  {
    id: 1,
    title: 'Jobs',
    href: '/nurse/jobs/search',
    children: false,
    loggedIn: false,
    classname: '',
    showLoggedIn: true,
  },
  {
    id: 2,
    title: 'Inbox',
    href: '/nurse/chat',
    children: false,
    loggedIn: true,
    classname: 'nav-inbox',
    showLoggedIn: true,
    showCounter: true
  },
  {
    id: 3,
    title: 'Profile',
    href: '/nurse/profile',
    children: false,
    loggedIn: true,
    classname: '',
    showLoggedIn: true,
  },
  {
    id: 4,
    title: 'Locations',
    href: '/nurse/jobs/travel-nursing-destinations',
    children: false,
    loggedIn: false,
    classname: 'cta_before_login_agency_reviews',
    showLoggedIn: true,
    activeItem: 'travel-nursing-destinations'
  },
  {
    id: 5,
    title: 'Products & Services',
    href: 'https://www.wanderly.us/products',
    children: false,
    loggedIn: false,
    classname: '',
    showLoggedIn: false,
  },
  {
    id: 6,
    title: 'Resources',
    href: '#',
    children: [
      {
        id: 1,
        title: 'Blogs',
        href: 'https://www.wanderly.us/blog/',
        children: false,
        loggedIn: false,
        classname: 'resources-tab',
      },
      {
        id: 3,
        title: 'Agencies',
        href: '/nurse/agencies',
        children: false,
        loggedIn: false,
        classname: 'resources-tab',
      },
      {
        id: 4,
        title: 'Housing',
        href: '/nurse/resources/housing',
        children: false,
        loggedIn: false,
        classname: 'resources-tab',
      },

    ],
    loggedIn: false,
    classname: '',
    showLoggedIn: true,
  },
];

export default NAVIGATION_MENU;