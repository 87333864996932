import React from 'react';

import { formatMoney, formatPayRate, getPaymentTypeAbbr } from '../../helpers/utils';

import css from './rate.module.scss';
import ReactTooltip from 'react-tooltip';

const Rate = ({ data }) => {
  const minPayment = +data.payment_min;
  const maxPayment = +data.payment_max;
  const jobType = +data.job_type;
  const isHourly = data.payment_type === 'hourly';

  const isPermanentRateDifferent = maxPayment.toFixed() !== minPayment.toFixed() && maxPayment.toFixed() > 0;

  if (jobType === 2) {
    return <div className={`${css.rate} text-end`}>
      <ReactTooltip
        arrowColor='#7B7B7C'
        backgroundColor='#7B7B7C'
        borderColor='#7B7B7C'
        className={`rc-tooltip ${css['rc-tooltip']} ${css['proposal-amount-tooltip']}`}
        effect='solid'
        id={`proposal-rate-${data.job_id || data.id}`}
      >
        <span className={css['price']}>
          {isHourly ? formatMoney(minPayment.toFixed(2), 2) : formatMoney(minPayment.toFixed(), 0)}
        </span>
        {(maxPayment > 0 && maxPayment > minPayment) && (
          <>
            <span className='separator'> - </span>
            <span className={'price'}>
              {isHourly ? formatMoney(maxPayment.toFixed(2), 2) : formatMoney(maxPayment.toFixed(), 0)}
            </span>
          </>
        )}
        /{getPaymentTypeAbbr(data.payment_type)}
      </ReactTooltip>

      <div data-for={`proposal-rate-${data.job_id || data.id}`} data-tip className='flex'>
        <div className={'sign'}>$</div>
        <span className={'price'}>
          {isHourly ? formatMoney(minPayment.toFixed(2), 2, '') : formatMoney(minPayment.toFixed(), 0, '')}
          <small>/{getPaymentTypeAbbr(data.payment_type)}{isPermanentRateDifferent ? '+' : ''}</small>
        </span>
      </div>
    </div>
  }

  if (!data.hide_pay_rate) {
    if (!data.pay_packages) {
      return (
        <div className={`${css.rate} text-end`}>
          <div className="sign">$</div>
          <span className="price">
            {isHourly ? formatPayRate(data.payment_min, 0) : formatPayRate(+data.payment_min, 0)}
          </span>
          <small>/{getPaymentTypeAbbr(data.payment_type)}</small>
        </div>
      );
    }

    const minPayment = isHourly
      ? formatPayRate(data.payment_min, 0)
      : formatPayRate(+data.payment_min, 0);
    const maxPayment = isHourly
      ? formatPayRate(data.payment_max, 0)
      : formatPayRate(+data.payment_max, 0);

    return (
      <div className={`${css.rate} text-end`}>
        {maxPayment > minPayment ? (
          <>
            {minPayment > 0 && (
              <>
                <div className="sign">$</div>
                <span className="price">{minPayment}</span>
              </>
            )}
            {maxPayment > 0 && (
              <>
                <span className="separator"> - </span>
                <div className="sign">$</div>
                <span className="price">{maxPayment}</span>
              </>
            )}
          </>
        ) : (
          <>
            <div className="sign">$</div>
            <span className="price">{minPayment}</span>
          </>
        )}
        <small>/{getPaymentTypeAbbr(data.payment_type)}</small>
      </div>
    );
  }

  return (
    <div className={`${css.rate} text-end`}>
      {data.hidden_pay_rate_text ? data.hidden_pay_rate_text : 'Contact recruiter for more details'}
    </div>
  );
};

export default Rate;
