import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProfessions,
  professionsSelector,
} from '../../redux/slices/professions';
import { ReactComponent as IconProfession } from '../../assets/images/icons/profession.svg';
import { ReactComponent as IconClose } from '../../assets/images/icons/closeDropdown.svg';
import DropdownControl from '../DropdownControl';
import {
  clearSidebarFilters,
  clearTopFilters,
  JobsSelector,
  setFilters,
  setPage,
  setShowProfessionUser,
} from '../../redux/slices/jobs';
import { components } from 'react-select';
import {
  setSpecialitiesOptionsDropdown,
  SpecialtiesSelector,
} from '../../redux/slices/specialties';

import './ProfessionDropdown.scss';
import {
  getLocations,
  setLocationsOptionsDropdown,
} from '../../redux/slices/locations';
import { populateJobsCount } from '../../redux/slices/jobsCounter';

const ProfessionDropdown = ({ value, onChange, isControlled = false, isDisabled=false }) => {
  const dispatch = useDispatch();
  const { professions } = useSelector(professionsSelector);
  const { jobsCount, filters: { profession_id, typeJob } = {} } = useSelector(JobsSelector);
  const { optionsDropdownOriginal } = useSelector(SpecialtiesSelector);

  useEffect(() => {
    if (!professions || professions.length <= 0) {
      dispatch(getProfessions());
    }
  }, [dispatch]);

  const onSelectOption = async (value) => {
    if (isControlled) {
      onChange(value ? value.value : null, '', 'profession');
    } else {
      if (value && profession_id) {
        if (+value.value === +profession_id.value) return;
      }

      dispatch(
        setFilters({
          profession_id: value,
          specialty_ids: null,
        })
      );

      dispatch(setShowProfessionUser(false));
      //dispatch(setSpecialitiesOptionsDropdown([]));

      if (value) {
        const filteredOptions = optionsDropdownOriginal
          .filter((o) => +o.profession_id === +value.value)
          .map((o) => {
            return {
              ...o,
              fakeLabel: o.label,
            };
          });

        // Reset all filters when switching between professions
        if (profession_id) {
          const locations = await getLocations('', true);
          await dispatch(setLocationsOptionsDropdown(locations));
          await dispatch(clearTopFilters({ profession_id: value, typeJob }));
          await dispatch(clearSidebarFilters());
        }

        await dispatch(setSpecialitiesOptionsDropdown(filteredOptions));
      } else {
        await dispatch(populateJobsCount(jobsCount, true));
        // await dispatch(setSpecialitiesOptionsDropdown(optionsDropdownOriginal));
      }
    }
  };

  const ClearIndicator = ({ children, ...props }) => {
    return !isControlled ? (
      <components.Control {...props} className="">
        <IconClose
          title={'Click to remove profession'}
          className="icon-close"
          onClick={() => {
            dispatch(
              setFilters({
                profession_id: null,
              })
            );
          }}
        />
      </components.Control>
    ) : null;
  };

  const getValue = () => {
    if (isControlled) {
      return value
        ? professions.filter((option) => option.value === value)
        : null;
    } else {
      return !isNaN(profession_id)
        ? professions.filter((option) => option.value === profession_id)
        : profession_id;
    }
  };

  const Option = (props) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        <label>{props.label}</label>{' '}
        {data.count ? <span className={'jobs-count'}>{data.count}</span> : null}
      </components.Option>
    );
  };

  return (
    <DropdownControl
      icon={<IconProfession />}
      classname="profession"
      options={professions}
      placeholder={'Select Profession'}
      onChange={onSelectOption}
      name="profession"
      value={getValue()}
      isClearable
      customComponents={{
        ClearIndicator,
        Option,
      }}
      isDisabled={isDisabled}
    />
  );
};

export default ProfessionDropdown;
