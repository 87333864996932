import React, {useEffect} from "react";

import {
  GetBlogPosts,
  GetFeaturedAgencies,
  GetFeaturedDestinations
} from "../../hooks/useHomepage";
import { useDispatch, useSelector } from 'react-redux';
import FeaturedDestinations from "../../components/FeaturedDestinations";
import CandidateReviews from "../../components/CandidateReviews";
import Blogs from "../../components/BlogSection";
import Partners from "../../components/Partners";
import FeaturedAgencies from "../../components/FeaturedAgencies";
import {hasAccessToken} from "../../helpers/auth";
import {getProfessionData, getUserData, NurseSelector} from "../../redux/slices/nurse";
import {setHomepageElements} from "../../helpers/dom";
import HeroSection from "../../components/HeroSection";
import FeaturedJobs from "../../components/FeaturedJobs";
import HowItWorks from "../../components/HowItWorks";
import AgenciesCarousel from "../../components/AgenciesCarousel";
import StatCounter from '../../components/StatCounter';

import  './Home.style.scss';
import {getNotifications} from "../../redux/slices/notifications";
import useModal from "../../hooks/useModal";
import AppModal from "../../components/appModal";
import { getLastJobFilters } from '../../helpers/utils';

const Home = () => {
  const dispatch = useDispatch();
  const {agenciesData} = GetFeaturedAgencies();
  const {destinationData} = GetFeaturedDestinations();
  const {blogData} = GetBlogPosts();
  const { auth: { isLoading } } = useSelector(NurseSelector);
  const [statusModal, toggleModal] = useModal();

  useEffect(() => {
    setHomepageElements();
  }, []);

  useEffect(() => {
    if (hasAccessToken()) {
      dispatch(getUserData());
      dispatch(getNotifications());
      if (!getLastJobFilters()) {
        dispatch(getProfessionData());
      }
    }
  }, [dispatch]);

  return (
    <section className="homepage-container">
      <HeroSection toggleModal={toggleModal} />
      <StatCounter showWanderlyApp={true} />
      <div className="bg-gray">
        <FeaturedJobs />
      </div>
      <HowItWorks />
      <div className="bg-gray">
        <AgenciesCarousel />
      </div>
      <FeaturedDestinations destinations={destinationData} />
      <div className="bg-gray">
        <CandidateReviews />
      </div>
      <Blogs posts={blogData} />
      <FeaturedAgencies agencies={agenciesData} />
      <Partners />

      {statusModal && (
        <AppModal toggle={toggleModal} showTitle={false} />
      )}
    </section>
  )
};

export default Home;
