import { useRef } from 'react';
import {ReactComponent as NextArrowIcon} from "../../assets/images/icons/carouselNextTeal.svg";
import {ReactComponent as PrevArrowIcon} from "../../assets/images/icons/carouselPrevTeal.svg";
import nurseKelley from '../../assets/images/pages/home/nurseKelley.png';
import nurseKelleyMobile from '../../assets/images/pages/home/small_devices/nurseKelley.png';

import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import {Slide} from "./Slide";

import './CandidateReview.scss';

const NextArrow = () => (
  <div className="carousel-next">
    <NextArrowIcon style={{ height: 19, marginTop: -8 }} />
  </div>
);

const PrevArrow = () => (
  <div className="carousel-prev">
    <PrevArrowIcon style={{ height: 19, marginTop: -8 }} />
  </div>
);


const CandidateReviews = () => {
  const ref = useRef(StackedCarousel);

  const reviewData = [
    {
      author: 'Kim',
      speciality: 'Ultrasound Technologist' ,
      review: "I found Wanderly a while back and I was looking at the website thinking, wow this is awesome!. At the time it was only for nurses and I was hoping in the future they'll bring along some radiology people. But I think Wanderly is such a great resource for travelers. It gives travelers another option to search for jobs and compare pay packages. I just love that it lays everything out there for you. I think it's a great resource and a great addition to help travelers find the jobs they're looking for.",
    },
    {
      author: 'LaLaine',
      speciality: 'Registered Nurse' ,
      review: " I really like how Wanderly gives me comprehensive information for different travel jobs. I do not have to keep looking through tons of agency information, everything I need to know to compare and choose a job is within Wanderly",
    },
    {
      author: 'Erran',
      speciality: 'Radiology IR Technologist' ,
      review: "I was amazed by it. I was telling the traveler I was working with about Wanderly. Just wait, allied is coming and when it does it is going to be amazing. I look for usability, which is one thing I always look for, and that was one thing I really loved about Wanderly. It's so simple to use! Boom this is what I've got. These are the areas, here are the jobs. Being able to compare pay packages, I was really shocked at some of them, but it was so cool to be able to see that because we've always done things over the phone. Being able to see the entire forest is amazing instead of having to look at each individual tree.",
    },
    {
      author: 'Brittany',
      speciality: 'Registered Nurse' ,
      review: " When I first became a travel nurse I was really overwhelmed with the amount of agencies. Through Wanderly, I can get online and look at everyone and all of the jobs in one spot and compare my needs without being pressured.",
    },
    {
      author: 'Alicia',
      speciality: 'Registered Nurse' ,
      review: "I stumbled upon Wanderly during my explorations as a new travel RN.  I didn't know much about the travel world (contract lengths, stipends, taxes) but I knew there was someway I could find the best contract for me without recruiters.  Wanderly was that answer.  I have been able to compare packages, hospitals types, specialty experience and much more with just a filter and a click of the mouse.  It has been very empowering to research contracts on my own and educate myself on different components that make up the best package for me.  Having recruiters call all the time is a hassle.  I will be using Wanderly for future assignments.",
    },
    {
      author: 'Sarah',
      speciality: 'Registered Nurse' ,
      review: "We are going to need everyone in this industry to adapt to technology and be flexible with new solutions like Wanderly. Wanderly understands what we want.",
    },
    {
      author: 'Kim',
      speciality: 'Ultrasound Technologist' ,
      review: "I found Wanderly a while back and I was looking at the website thinking, wow this is awesome! At the time it was only for nurses and I was hoping in the future they'll bring along some radiology people. But I think Wanderly is such a great resource for travelers. It gives travelers another option to search for jobs and compare pay packages. I just love that it lays everything out there for you. I think it's a great resource and a great addition to help travelers find the jobs they're looking for.",
    },
    {
      author: 'Erran',
      speciality: 'Radiology IR Technologist' ,
      review: "I was amazed by it. I was telling the traveler I was working with about Wanderly. Just wait, allied is coming and when it does it is going to be amazing. I look for usability, which is one thing I always look for, and that was one thing I really loved about Wanderly. It's so simple to use! Boom this is what I've got. These are the areas, here are the jobs. Being able to compare pay packages, I was really shocked at some of them, but it was so cool to be able to see that because we've always done things over the phone. Being able to see the entire forest is amazing instead of having to look at each individual tree.",
    },
  ]

  const getReviews = () => {
     return reviewData.map((item, index) => {
        return (<div className="candidate-reviews-container" key={index}>
          <p>
            {item.review.substring(0, 200) + "..."}
          </p>
          <div className="author">
            <b>{item.author},</b> {item.speciality}
          </div>
        </div>);
      });
  }

  return (<section className="candidates-reviews-section">
    <div className="homepage-section ">
      <div className="text-left full-width title-container">
        <h5 className="title">Testimonials</h5>
      </div>
      <div className="review-container row">
        <div className="col-sm-auto banner-container ">
          <h2> What Candidates Say About Us</h2>

          <p className="quote">
            Wanderly is a place made for healthcare professionals
            to compare travel healthcare jobs from leading agencies.
            Browse transparent job offers and Quick Apply.
          </p>
        </div>
        <div className="col info-container">
          <div className="stacked-carousel">
            <ResponsiveContainer
                carouselRef={ref}
                render={(width, carouselRef) => {
                  let currentVisibleSlide = 450;
                  let fadeDistance = 0.3;

                  if (width < 390) {
                    currentVisibleSlide = 280;
                    fadeDistance = 1;
                  } else if (width >= 390) {
                    currentVisibleSlide = 330;
                    fadeDistance = 0.3;
                  } else {
                    currentVisibleSlide = 450;
                    fadeDistance = 0.3;
                  }

                  return (
                      <StackedCarousel
                          ref={carouselRef}
                          slideComponent={Slide}
                          slideWidth={currentVisibleSlide}
                          carouselWidth={width}
                          data={reviewData}
                          maxVisibleSlide={5}
                          disableSwipe
                          customScales={[1, 0.85, 0.7, 0.55]}
                          transitionTime={450}
                          fadeDistance={fadeDistance}
                      />
                  );
                }}
            />

            <div className="arrow-container">
              <div className="arrow-container-inner">
                <div className="arrow-left" onClick={() => ref.current?.goBack()}>
                  <PrevArrow  />
                </div>
                <div> / </div>
                <div className="arrow-right" onClick={() => ref.current?.goNext()}>
                  <NextArrow  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>);
}


export default CandidateReviews;